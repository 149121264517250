import React, { Fragment, lazy, Suspense, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames/bind";
import { isEmpty, get } from "lodash";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import Hidden from "@material-ui/core/Hidden";
import DoneIcon from "@material-ui/icons/DoneOutlined";
import AlertMsg from "components/Shared/OfferBox/AlertMsg";
import Collapse from "react-css-collapse";
import { getNumberWith2Decimals } from "utils/helper.js";
import { Autocomplete } from "components/FormFields";
import MapDialog from "components/Layout/Offer/MapDialog";

const DateRangeSelector = lazy(() =>
  import("components/Shared/OfferBox/DateRangePicker")
);

const OfferBox = ({
  classes,
  selected,
  filter,
  id,
  title,
  price,
  rates,
  standardUnitPrice,
  priceMsg,
  offMsg,
  spaceLeft,
  warnings,
  description,
  extras,
  showCalendar,
  required,
  handleSelect,
  listOfSpaces,
  actions,
  selectedOffer,
  selectedProperties,
  requireSpaceSelection
}) => {
  const [mapDialogOpen, setMapDialogOpen] = useState(false);

  const cx = classNames.bind(classes);
  let select = () => {};
  if (typeof handleSelect == "function") {
    select = id => handleSelect.bind(this, id);
  }
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const show = () => {
    setDisplayCalendar(!displayCalendar);
  };

  const hasListOfSpaces = (listOfSpaces || []).length > 0;

  return (
    <>
      <MapDialog
        open={mapDialogOpen}
        handleClose={() => setMapDialogOpen(false)}
      />
      <Paper
        id={id}
        key={id}
        className={cx("paper", { selected })}
        elevation={1}
      >
        <div className={classes.row}>
          <div
            className={cx("leftCol", {
              borderLeftCol: typeof handleSelect == "function"
            })}
          >
            <Typography gutterBottom className={classes.title} variant="h6">
              {title}
            </Typography>
            {!isEmpty(description) && (
              <Typography
                gutterBottom
                className={classes.description}
                variant="body1"
              >
                {description}
              </Typography>
            )}
            <Typography
              gutterBottom
              className={classes.priceText}
              variant="body1"
            >
              <span className={classes.price}>
                ${getNumberWith2Decimals(price)}
              </span>{" "}
              {/* average {priceMsg} */}
              average per night
            </Typography>
            <Typography
              gutterBottom
              className={classes.discount}
              variant="body1"
            >
              {offMsg}
            </Typography>
            {warnings.map(
              (warning, i) =>
                warning && (
                  <Typography
                    id={i}
                    key={i}
                    gutterBottom
                    className={classes.alert}
                    variant="body1"
                  >
                    {warning}
                  </Typography>
                )
            )}
            {required && (
              <Typography
                id="required"
                key="required"
                gutterBottom
                className={classes.alert}
                variant="body1"
              >
                This extra is required to continue
              </Typography>
            )}
            {!isEmpty(extras) &&
              extras.map(i => {
                return (
                  <Typography
                    id="required"
                    key={i.id}
                    gutterBottom
                    variant="body2"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DoneIcon className={classes.icon} />
                    {`${
                      get(i, "required") === true ? "Required" : "Optional"
                    } extra - ${i.addonName}`}
                  </Typography>
                );
              })}
            {hasListOfSpaces && (
              <Typography
                gutterBottom
                variant="body1"
                onClick={() => setMapDialogOpen(true)}
                className={classes.showText}
              >
                Show Map
              </Typography>
            )}
            <Typography
              gutterBottom
              variant="body1"
              onClick={show}
              className={classes.showText}
            >
              {showCalendar &&
                (!displayCalendar
                  ? "Show Pricing Calendar"
                  : "Hide Pricing Calendar")}
            </Typography>
            {hasListOfSpaces && (
              <Autocomplete
                id="spaceList"
                label={
                  requireSpaceSelection
                    ? "Select Space (Required)"
                    : "Select Space (Optional)"
                }
                value={
                  selected
                    ? get(selectedOffer, "spaces", []).find(
                        i => i.spaceHashId === selectedProperties.selectedSpace
                      ) || ""
                    : ""
                }
                values={listOfSpaces}
                rootClassName={classes.listOfSpaces}
                submitValue={val =>
                  actions.addToSelected({
                    selectedSpace: get(val, "option.spaceHashId", "")
                  })
                }
                labelPath="spaceName"
                disabled={!selected}
                required={selected && requireSpaceSelection}
                onClick={select(id)}
                className={classes.showSpace}
              />
            )}
            <Hidden smUp>
              {showCalendar && (
                <Collapse
                  isOpen={displayCalendar}
                  transition="height 250ms cubic-bezier(.4, 0, .2, 1)"
                >
                  <div className={classes.calendar}>
                    <Suspense fallback={<div>Loading...</div>}>
                      <DateRangeSelector
                        filter={filter}
                        rates={rates}
                        standardUnitPrice={standardUnitPrice}
                        daySize={33}
                      />
                    </Suspense>
                  </div>
                </Collapse>
              )}
            </Hidden>
          </div>
          {typeof handleSelect == "function" && (
            <div className={classes.RightCol}>
              {spaceLeft > 0 && spaceLeft < 6 && (
                <AlertMsg amount={spaceLeft} />
              )}
              <Button
                variant="contained"
                color="primary"
                className={cx("button", { selected })}
                onClick={select(id)}
              >
                {selected ? (
                  <Fragment>
                    <CheckIcon className={classes.leftIcon} />
                    Selected
                  </Fragment>
                ) : (
                  "Select"
                )}
              </Button>
            </div>
          )}
        </div>
        <Hidden only="xs">
          <div className={classes.row}>
            {showCalendar && (
              <Collapse
                isOpen={displayCalendar}
                transition="height 250ms cubic-bezier(.4, 0, .2, 1)"
              >
                <div className={classes.calendar}>
                  <Suspense fallback={<div>Loading...</div>}>
                    <DateRangeSelector
                      filter={filter}
                      rates={rates}
                      standardUnitPrice={standardUnitPrice}
                      daySize={75}
                    />
                  </Suspense>
                </div>
              </Collapse>
            )}
          </div>
        </Hidden>
      </Paper>
    </>
  );
};

OfferBox.propTypes = {
  classes: PropTypes.object,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  filter: PropTypes.object,
  title: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  rates: PropTypes.array,
  priceMsg: PropTypes.string,
  offMsg: PropTypes.string,
  warnings: PropTypes.array,
  description: PropTypes.string,
  standardUnitPrice: PropTypes.number,
  extras: PropTypes.array,
  spaceLeft: PropTypes.number,
  showCalendar: PropTypes.bool,
  required: PropTypes.bool,
  handleSelect: PropTypes.func
};

OfferBox.defaultProps = {
  warnings: [],
  rates: [],
  filter: {},
  priceMsg: "",
  offMsg: "",
  description: "",
  spaceLeft: 0,
  standardUnitPrice: 0,
  showCalendar: false,
  required: false,
  extras: [],
  handleSelect: null
};

OfferBox.displayName = "OfferBox";

export default OfferBox;
