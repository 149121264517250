import * as SIZE from "utils/constants";

export default theme => ({
  root: {
    maxWidth: 638,
    borderRadius: 6,
    backgroundColor: "#FFFFFF",
    boxShadow: "0 4px 8px 0 rgba(0,0,0,0.1)",
    padding: 48,
    fontWeight: 400,

    [theme.breakpoints.down(SIZE.MEDIUM)]: {
      padding: "28px",
      maxWidth: "none",

      "& > div": {
        flexDirection: "column"
      }
    }
  },
  title: {
    color: "#202124",
    fontSize: 22,
    lineHeight: "30px"
  },
  price: {
    color: "#67AAD1",
    fontSize: 28,
    lineHeight: "26px",
    marginTop: 38,

    "& span": {
      fontSize: 14,
      color: "#A7AAAD",
      display: "inline-block",
      transform: "translate(-2px, -2px)"
    },

    [theme.breakpoints.down(SIZE.MEDIUM)]: {
      marginTop: 24
    }
  },
  buttonColumn: {
    flex: 1,
    marginLeft: 56,
    borderLeft: "1px solid #E5E5E5",
    alignItems: "center",
    justifyContent: "flex-end",

    [theme.breakpoints.down(SIZE.MEDIUM)]: {
      borderLeft: "none",
      marginLeft: 0,
      marginTop: 20
    }
  },
  submitButton: {
    height: 56,
    width: 110,
    color: "#fff",
    fontFamily: "Maven Pro",
    fontSize: 18,
    textAlign: "center",
    backgroundColor: "#67AAD1",
    borderRadius: 4,

    "&:hover": {
      backgroundColor: "#67AAD1"
    },
    "&[disabled]": {
      backgroundColor: "rgba(0, 0, 0, 0.12)"
    },

    [theme.breakpoints.down(SIZE.MEDIUM)]: {
      width: "100%"
    }
  },
  buttonText: {
    textTransform: "none",
    fontSize: 18
  },
  selectContainer: {
    marginTop: 20
  }
});
