import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import FormControl from "@material-ui/core/FormControl";
import { TextField } from "components/FormFields";
import styles from "./styles.js";
import { get } from "lodash";
import classNames from "classnames";

const AutocmpleteOption = ({
  id,
  label,
  classes,
  className,
  value,
  values,
  disabled,
  required,
  submitValue,
  rootClassName,
  labelPath,
  idPath,
  ...rest
}) => {
  const getOption = option => get(option, labelPath, option.value || option);

  return (
    <FormControl
      variant="outlined"
      className={classNames(classes.formControl, {
        [rootClassName]: !!rootClassName
      })}
      error={required && !value}
    >
      <Autocomplete
        id={label || id}
        value={value || ""}
        options={values}
        autoHighlight
        getOptionLabel={getOption}
        renderOption={getOption}
        onChange={(_, v) =>
          submitValue({
            id: id,
            value: v && (v.value || v),
            required: required,
            option: v
          })
        }
        disabled={disabled}
        autoSelect
        clearOnEscape
        disableOpenOnFocus
        className={className || classes.textField}
        renderInput={props => (
          <TextField
            id={label || id}
            label={label}
            error={required && !value}
            fullWidth
            submitValue={e => e}
            inputProps={{
              name: id,
              id: id,
              autoComplete: "new-password"
            }}
            {...props}
            {...rest}
          />
        )}
      />
    </FormControl>
  );
};

AutocmpleteOption.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object
  ]),
  values: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  classes: PropTypes.object,
  className: PropTypes.object,
  submitValue: PropTypes.func.isRequired
};

AutocmpleteOption.defaultProps = {
  disabled: false
};

export default withStyles(styles)(AutocmpleteOption);
