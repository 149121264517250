const URI = window["runtime"].apiUrl;

export const siteverify = `${URI}/booking/marina/siteverify`;
export const accommodationemail = `${URI}/booking/marina/accommodationemail`;
export const marina = `${URI}/booking/marina/data`;
export const marinas = `${URI}/booking/marina/list`;
export const offers = `${URI}/booking/marina/offers`;
export const holdDockageOffer = `${URI}/booking/marina/offerhold`;
export const holdResourceOffer = `${URI}/booking/marina/resourceOfferHold`;
export const holdExtra = `${URI}/booking/marina/extrahold`;
export const payDockage = `${URI}/booking/marina/dobooking`;
export const payResource = `${URI}/booking/marina/AddResourceBooking`;
export const resources = `${URI}/booking/marina/allResourceOffers`;
export const checkResourceAvailability = `${URI}/booking/marina/resourceOffer`;
export const map = `${URI}/booking/marina/spaceMap`;
