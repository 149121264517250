import React, { Fragment, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import classNames from "classnames/bind";
import { get, findLastKey, isEmpty, intersection } from "lodash";
import { setContactDetails, setBoatDetails } from "utils/localStorage.js";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CountDown from "components/Layout/Offer/Countdown";
import Dialog from "components/Layout/Offer/Action/Dialog";
import publicIp from "public-ip";
import moment from "moment";
import { setUserVars } from "react-fullstory";
import { parseShorePower } from "redux/offer/utils.js";
import { getNumberWith2Decimals } from "utils/helper.js";
import { DATE_FORMAT } from "utils/constants.js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import * as ROUTES from "utils/constants.js";
import useAnalytics from "hooks/useAnalytics";

const TITLE = "We are holding your space while you book. Time remaining: ";
let publicIpV4 = "";
(async () => {
  publicIpV4 = await publicIp.v4();
})();

const Action = ({
  classes,
  isLoading,
  addFilterAction,
  holdAction,
  holdExtraAction,
  payAction,
  payStripeAction,
  match,
  location,
  currentPage,
  filter,
  marina,
  selectedOffer,
  offerList,
  extraList,
  selectedExtra,
  selectedExtraList,
  contactDetail,
  boatDetail,
  paymentDetail,
  history,
  selectedOfferProperties,
  ...rest
}) => {
  const { notifyUser } = useAnalytics();

  const cx = classNames.bind(classes);
  const stripe = useStripe();
  const elements = useElements();
  const [disabled, setDisabled] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const redirectRoute = useMemo(() => {
    if (get(selectedOffer, "type") === ROUTES.OFFER_TYPES.RESOURCE) {
      return ROUTES.RESOURCES;
    } else {
      // SelectedOffer can be undefined for Dockage.
      return ROUTES.SEARCH;
    }
  }, [selectedOffer]);

  useEffect(() => {
    const showContinue = details => {
      const validFields = findLastKey(details, {
        isValid: false
      });
      const requiredFields = findLastKey(details, {
        value: "",
        required: true
      });
      const requiredCheckboxes = findLastKey(details, {
        value: false,
        required: true
      });
      const required =
        !isEmpty(validFields) ||
        !isEmpty(requiredFields) ||
        !isEmpty(requiredCheckboxes);
      required ? setDisabled(true) : setDisabled(isLoading);
    };
    switch (currentPage.url) {
      case ROUTES.OFFERS:
        const emptyRequiredSpace =
          get(selectedOffer, "requireSpaceSelection") &&
          !get(selectedOfferProperties, "selectedSpace");

        if (!get(selectedOffer, "id") || emptyRequiredSpace) {
          setDisabled(true);
        } else {
          setDisabled(isLoading);
        }
        break;
      case ROUTES.EXTRAS:
        if (isEmpty(extraList)) {
          history.push(ROUTES.CONTACT_DETAILS);

          return;
        }
        const requiredFields = extraList
          .filter(i => i.required === true)
          .map(i => i.id);
        const intersect = intersection(requiredFields, selectedExtra);
        requiredFields.length !== intersect.length
          ? setDisabled(true)
          : setDisabled(isLoading);
        break;
      case ROUTES.CONTACT_DETAILS:
        showContinue(contactDetail);
        break;
      case ROUTES.BOAT_DETAILS:
        showContinue(boatDetail);
        break;
      case ROUTES.PAYMENT:
        showContinue(paymentDetail);
        break;
      default:
        break;
    }
  }, [
    isLoading,
    selectedOffer,
    selectedExtra,
    contactDetail,
    boatDetail,
    paymentDetail,
    currentPage.url,
    extraList,
    history,
    selectedOfferProperties
  ]);

  const submit = currentPage => {
    let nextUrl = currentPage.nextUrl;
    if (currentPage.url === ROUTES.OFFERS) {
      if (
        get(selectedOffer, "updatedDepartureDate") !== "0001-01-01T00:00:00"
      ) {
        const newValue = moment(
          get(selectedOffer, "updatedDepartureDate")
        ).format(DATE_FORMAT);
        addFilterAction({
          departure: { required: true, value: newValue }
        });
        filter.departure = { required: true, value: newValue };
      }
      holdAction(filter, selectedOffer.id, marina.MarinaId);
      // remove next lines if holdExtraAction is enabled
      if (isEmpty(extraList)) {
        nextUrl = ROUTES.CONTACT_DETAILS;
      }
      history.push(nextUrl);
    }
    if (currentPage.url === ROUTES.EXTRAS) {
      // holdExtraAction(selectedExtra, selectedOffer.id, marina.MarinaId);
      history.push(nextUrl);
    }
    if (currentPage.url === ROUTES.CONTACT_DETAILS) {
      setUserVars({
        displayName: `${get(contactDetail, "firstName.value")} ${get(
          contactDetail,
          "lastName.value"
        )}`,
        email: get(contactDetail, "email.value", "")
      });
      setContactDetails(contactDetail);

      notifyUser();

      history.push(nextUrl);
    }
    if (currentPage.url === ROUTES.BOAT_DETAILS) {
      setBoatDetails(boatDetail);
      history.push(nextUrl);
    }
    if (currentPage.url === ROUTES.PAYMENT) {
      // if stripe do a sync submit, else async
      if (isEmpty(get(paymentDetail, "cardNumber.value"))) {
        const card = elements.getElement("card");
        payStripeAction({
          filter,
          selectedOffer,
          selectedExtraList,
          contactDetail,
          boatDetail,
          paymentDetail,
          stripe,
          card,
          publicIpV4
        });
      } else {
        payAction({
          filter,
          selectedOffer,
          selectedExtraList,
          contactDetail,
          boatDetail,
          paymentDetail,
          publicIpV4
        });
      }
    }
  };

  const search = () => {
    history.push(redirectRoute);
  };

  return (
    <Fragment>
      {redirect && <Redirect to={redirectRoute} />}
      {!isEmpty(offerList) && (
        <Paper className={classes.paper} elevation={1}>
          {currentPage.url !== ROUTES.OFFERS && (
            <CountDown title={TITLE} handleDisabled={() => setDisabled(true)} />
          )}

          <Button
            id={
              currentPage.url === ROUTES.PAYMENT
                ? "Pay Now"
                : currentPage.url === ROUTES.OFFERS
                ? "Start Booking"
                : "Continue"
            }
            variant="contained"
            color="primary"
            className={classes.button}
            disabled={disabled}
            onClick={() => submit(currentPage)}
          >
            {currentPage.url === ROUTES.PAYMENT
              ? "Pay Now"
              : currentPage.url === ROUTES.OFFERS
              ? "Start Booking"
              : "Continue"}
          </Button>
          {currentPage.url === ROUTES.OFFERS &&
            get(selectedOffer, "minimumPayment", 0) !== 0 && (
              <Typography
                className={classes.typography}
                gutterBottom
                variant="body1"
              >
                Book now with a $
                {getNumberWith2Decimals(get(selectedOffer, "minimumPayment"))}{" "}
                deposit.
              </Typography>
            )}
        </Paper>
      )}
      <Paper
        className={cx("search", { extraHeight: !isEmpty(offerList) })}
        elevation={1}
      >
        <Typography gutterBottom variant="h6">
          Your Search
        </Typography>
        <Typography gutterBottom variant="body1">
          Arrival: <span>{get(filter, "arrival.value")}</span>
        </Typography>
        <Typography gutterBottom variant="body1">
          Departure: <span>{get(filter, "departure.value")}</span>
        </Typography>
        <Typography gutterBottom variant="body1">
          {get(filter, "length.value") && (
            <>
              Length: <span>{get(filter, "length.value")}</span>
            </>
          )}
          {get(filter, "beam.value") && (
            <>
              , Beam: <span>{get(filter, "beam.value")}</span>
            </>
          )}
          {get(filter, "draft.value") && (
            <>
              , Draft: <span>{get(filter, "draft.value")}</span>
            </>
          )}
        </Typography>
        {get(filter, "shorePower.value", 0) !== 0 && (
          <Typography gutterBottom variant="body1">
            Shore Power:{" "}
            <span>{parseShorePower(get(filter, "shorePower.value"), 0)}</span>
          </Typography>
        )}
        {currentPage.url === ROUTES.OFFERS ? (
          <Button
            id="changeSearch"
            variant="contained"
            color="primary"
            className={classes.buttonSearch}
            onClick={() => search()}
            disabled={isLoading}
          >
            Change search
          </Button>
        ) : (
          <Button
            id="changeSearch"
            variant="contained"
            color="primary"
            className={classes.buttonSearch}
            onClick={() => {
              setShowDialog(true);
            }}
            disabled={isLoading}
          >
            Change search
          </Button>
        )}
        <Dialog
          open={showDialog}
          handleCloseOk={() => {
            setShowDialog(false);
            setRedirect(true);
          }}
          handleCloseCancel={() => {
            setShowDialog(false);
          }}
        />
      </Paper>
    </Fragment>
  );
};

Action.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentPage: PropTypes.shape({
    url: PropTypes.string.isRequired,
    nextUrl: PropTypes.string.isRequired
  }).isRequired,
  addFilterAction: PropTypes.func.isRequired,
  holdAction: PropTypes.func.isRequired,
  holdExtraAction: PropTypes.func.isRequired,
  payAction: PropTypes.func.isRequired,
  payStripeAction: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  marina: PropTypes.object.isRequired,
  selectedOffer: PropTypes.object,
  offerList: PropTypes.array,
  extraList: PropTypes.array,
  selectedExtra: PropTypes.array,
  selectedExtraList: PropTypes.array,
  contactDetail: PropTypes.object,
  boatDetail: PropTypes.object
};

Action.defaultProps = {
  addFilterAction: e => e,
  holdAction: e => e,
  holExtradAction: e => e,
  payAction: e => e,
  payStripeAction: e => e,
  selectedOffer: {},
  marina: {},
  extraList: [],
  offerList: [],
  selectedExtra: [],
  selectedExtraList: [],
  contactDetail: {},
  boatDetail: {},
  paymentDetail: {}
};

Action.displayName = "Action";

export default Action;
