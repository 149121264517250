import * as SIZE from "utils/constants.js";

export default theme => ({
  paper: {
    border: `1px solid transparent`,
    flexDirection: "column",
    display: "flex",
    borderRadius: "10px",
    marginBottom: "35px",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      flexDirection: "column",
      padding: "20px 0"
    }
  },
  row: {
    display: "flex",
    paddingTop: "40px",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      display: "block"
    }
  },
  selected: {
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    backgroundColor: "white",
    "&:hover": {
      backgroundColor: "white"
    }
  },
  leftCol: {
    display: "flex",
    flexDirection: "column",
    flex: "0.75",
    padding: "0 35px",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      borderRight: "none",
      width: "inherit",
      padding: "0 20px"
    }
  },
  borderLeftCol: {
    borderRight: "1px solid #E5E5E5"
  },
  priceText: {
    display: "flex",
    alignItems: "center",
    textTransform: "lowercase",
    margin: "1.2em 0"
  },
  price: {
    color: theme.palette.primary.main,
    marginRight: "5px",
    fontSize: "20px"
  },
  discount: {
    color: theme.palette.error.main,
    marginBottom: "1.2em"
  },
  alert: {
    backgroundColor: "white",
    color: theme.palette.error.main,
    borderRadius: "5px",
    border: `1px solid ${theme.palette.error.main}`,
    padding: "10px",
    marginBottom: "1.2em"
  },
  RightCol: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    flex: "0.25",
    padding: "0 30px",
    textAlign: "justify",
    [theme.breakpoints.down(SIZE.XSMALL)]: {
      width: "inherit",
      padding: "0 20px"
    }
  },
  button: {
    padding: "15px",
    minWidth: "150px"
  },
  leftIcon: {
    marginRight: "10px",
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "25px"
  },
  description: {
    textAlign: "justify"
  },
  calendar: {
    display: "flex",
    flex: "1",
    justifyContent: "center",
    padding: "25px",
    borderTop: "1px solid #E5E5E5"
  },
  showText: {
    cursor: "pointer",
    marginBottom: "15px",
    textDecoration: "underline"
  },
  showSpace: {
    marginBottom: "15px"
  },
  listOfSpaces: {
    marginTop: 7
  },
  icon: {
    margin: theme.spacing(),
    marginLeft: 0,
    fontSize: 20,
    color: theme.palette.primary.main,
    borderRadius: "25px",
    padding: "3px"
  }
});
