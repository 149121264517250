import axios from "axios";
import * as API from "redux/api";
import { get } from "lodash";
import {
  parseCustomFilters,
  parseOffer,
  parseOffers,
  parseShorePower
} from "./utils";
import { OFFER_TYPES } from "utils/constants";
import { findIndex, isEmpty } from "lodash";
import moment from "moment";

//Action types
const ADD = "ADD_TO_OFFER";
const RESET_OFFER = "RESET_OFFER";
const SET_OFFER_TYPE = "SET_OFFER_TYPE";
const RESET_SELECTED_OFFER = "RESET_SELECTED_OFFER";
const ADD_TO_SELECTED = "ADD_TO_SELECTED";

//Functions
export const searchOffer = (filter, marinaId) => {
  return async dispatch => {
    try {
      dispatch(
        add({
          loading: true,
          loaded: false
        })
      );
      axios
        .post(API.offers, {
          ArrivalDate: get(filter, "arrival.value", ""),
          DepartureDate: get(filter, "departure.value", ""),
          MarinaId: marinaId,
          GroupCode: get(filter, "groupCode.value", ""),
          PowerNeed1: parseShorePower(get(filter, "shorePower.value"), 1),
          PowerNeed2: parseShorePower(get(filter, "shorePower.value"), 2),
          VesselLOA: get(filter, "length.value", 1).replace(",", "."),
          VesselBeam: get(filter, "beam.value", 1).replace(",", "."),
          VesselDraft: get(filter, "draft.value", 1).replace(",", ".")
        })
        .then(response => {
          dispatch(
            add({
              list: parseOffers(response.data),
              offerPostLogSetId: response.data.OfferPostLogSetId,
              loaded: true,
              loading: false,
              error: "",
              type: OFFER_TYPES.DOCKAGE
            })
          );
        })
        .catch(error => {
          dispatch(
            add({ error: get(error, "response.data.Message", "Error") })
          );
        })
        .finally(() => dispatch(add({ loading: false })));
    } catch (error) {
      dispatch(add({ loading: false }));
    }
  };
};

export const searchMap = marinaId => {
  return async dispatch => {
    axios
      .get(API.map, {
        params: {
          MarinaId: marinaId
        }
      })
      .then(response => {
        dispatch(
          add({
            map: response.data
          })
        );
      });
  };
};

export const holdDockage = (filter, offerId, marinaId) => {
  return async (dispatch, getState) => {
    try {
      const { offer } = getState();

      dispatch(
        add({
          loading: true,
          loaded: false,
          holded: false,
          error: ""
        })
      );

      axios
        .post(API.holdDockageOffer, {
          MarinaId: marinaId,
          OfferID: offerId,
          GroupCode: get(filter, "groupCode.value", ""),
          ArrivalDate: get(filter, "arrival.value", ""),
          DepartureDate: get(filter, "departure.value", ""),
          VesselLOA: get(filter, "length.value", 1),
          VesselBeam: get(filter, "beam.value", 1),
          VesselDraft: get(filter, "draft.value", 1),
          requestedSpaceHashId: get(offer, "selected.selectedSpace")
        })
        .then(response => {
          dispatch(
            addToSelected({
              onlineTerms: get(response, "data.OnlineTerms")
            })
          );

          dispatch(
            add({
              hold: {
                expirationDateTime: get(
                  response,
                  "data.ExpirationDateTime",
                  null
                ),
                offerHoldID: get(response, "data.OfferHoldID", null)
              },
              holded: true
            })
          );
        })
        .catch(error => {
          dispatch(add({ error: error.response.data.Message }));
        })
        .finally(() => {
          dispatch(add({ loading: false, loaded: true }));
        });
    } catch (error) {
      dispatch(add({ loading: false }));
    }
  };
};

export const holdResource = () => {
  return async (dispatch, getState) => {
    const state = getState();

    try {
      dispatch(
        add({
          loading: true,
          loaded: false,
          holded: false,
          error: ""
        })
      );
      axios
        .post(API.holdResourceOffer, {
          StartDate: get(state, "search.arrival.value", ""),
          EndDate: get(state, "search.departure.value", ""),
          StartTime: null,
          EndTime: null,
          OfferID: get(state, "offer.selected.id", ""),
          MarinaId: get(state, "marina.data.MarinaId", ""),
          RequestedResource: get(state, "offer.selected.spaceId", ""),
          GroupCode: null
        })
        .then(response => {
          dispatch(
            add({
              hold: {
                resourceAssignmentId: get(
                  response,
                  "data.ResourceAssignmentId",
                  null
                )
              },
              holded: true
            })
          );
        })
        .catch(error => {
          dispatch(add({ error: error.response.data.Message }));
        })
        .finally(() => {
          dispatch(add({ loading: false, loaded: true }));
        });
    } catch (error) {
      dispatch(add({ loading: false }));
    }
  };
};

export const searchMarinaResources = fromResourceDetail => {
  return async (dispatch, getState) => {
    // TODO: Use filters when required

    const {
      marina: {
        data: { MarinaId }
      },
      search: { arrival, departure, custom }
    } = getState();

    dispatch(add({ loading: true }));

    const isSomeFilterApplied =
      arrival.value || departure.value || !isEmpty(custom);

    const initialLoad = !fromResourceDetail && !isSomeFilterApplied;

    const CustomFields = parseCustomFilters(custom);

    axios
      .post(API.resources, {
        MarinaId,
        StartDate: arrival.value || moment().format("MM/DD/YY"),
        EndDate: departure.value || moment().add(1, "day").format("MM/DD/YY"),
        CustomFields: CustomFields.length > 0 ? CustomFields : undefined,
        InitialLoad: initialLoad ? initialLoad : undefined
      })
      .then(response => {
        dispatch(
          add({
            list: parseOffers({ Offers: response.data })
          })
        );
      })
      .catch(error => {
        dispatch(add({ error: get(error, "response.data.Message", "Error") }));
      })
      .finally(() => {
        dispatch(add({ loading: false, loaded: true }));
      });
  };
};

export const checkResourceOfferAvailability = offerID => {
  return async (dispatch, getState) => {
    // TODO: Use filters when required

    const {
      marina: {
        data: { MarinaId }
      },
      search: { arrival, departure, custom }
    } = getState();

    dispatch(
      add({
        loading: true,
        isCheckingAvailability: true
      })
    );

    const CustomFields = parseCustomFilters(custom);

    axios
      .post(API.checkResourceAvailability, {
        MarinaId,
        OfferId: offerID,
        StartDate: arrival.value || moment().format("MM/DD/YY"),
        EndDate: departure.value || moment().add(1, "day").format("MM/DD/YY"),
        GroupCode: null,
        CustomFields: CustomFields.length > 0 ? CustomFields : undefined
      })
      .then(response => {
        const [...newList] = getState().offer.list;

        const oldResourceIndex = findIndex(
          newList,
          el => el.offerID === offerID
        );

        newList[oldResourceIndex] = {
          ...newList[oldResourceIndex],
          ...parseOffer(response.data)
        };

        dispatch(add({ list: newList, error: "" }));
      })
      .catch(error => {
        dispatch(add({ error: get(error, "response.data.Message", "Error") }));
      })
      .finally(() => {
        dispatch(
          add({
            loading: false,
            isCheckingAvailability: false
          })
        );
      });
  };
};

//Action creators
export function add(payload) {
  return {
    type: ADD,
    payload
  };
}

export function resetOfferStore() {
  return {
    type: RESET_OFFER
  };
}

export function setOfferTypeAsDockage() {
  return {
    type: SET_OFFER_TYPE,
    payload: OFFER_TYPES.DOCKAGE
  };
}

export function setOfferTypeAsResource() {
  return {
    type: SET_OFFER_TYPE,
    payload: OFFER_TYPES.RESOURCE
  };
}

export function resetSelectedOffer() {
  return {
    type: RESET_SELECTED_OFFER
  };
}

export function addToSelected(payload) {
  return {
    type: ADD_TO_SELECTED,
    payload
  };
}

//Reducers
const initialState = {
  list: [],
  error: "",
  selected: {},
  loaded: false,
  holded: false,
  loading: false,
  type: "",
  isCheckingAvailability: false,
  userNote: "",
  map: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD:
      return { ...state, ...action.payload };
    case ADD_TO_SELECTED:
      return {
        ...state,
        selected: {
          ...state.selected,
          ...action.payload
        }
      };
    case SET_OFFER_TYPE:
      return { ...state, type: action.payload };
    case RESET_OFFER:
    case "RESET":
      return initialState;
    case RESET_SELECTED_OFFER:
      return {
        ...initialState,
        list: state.list,
        type: state.type
      };
    default:
      return state;
  }
};
