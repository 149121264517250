import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Route, Redirect, Prompt } from "react-router-dom";
import Header from "components/Layout/Header";
import Footer from "components/Layout/Footer";
import Grid from "@material-ui/core/Grid";
import Action from "components/Layout/Offer/Action";
import Breadcrumb from "components/Layout/Offer/Breadcrumb";
import Title from "components/Shared/Title";
import { get } from "lodash";
import { beforeunload, BEFOREUNLOAD_MSG } from "utils/listener.js";
import { OFFER_TYPES, CONTACT_DETAILS, EXTRAS, SEARCH } from "utils/constants";
import { isEmpty } from "lodash";
import { getPages } from "utils/helper";

const OfferLayout = ({
  component: Component,
  classes,
  actions,
  isLoading,
  filter,
  marina,
  selectedOffer,
  offerList,
  extraList,
  selectedExtra,
  selectedExtraList,
  contactDetail,
  boatDetail,
  paymentDetail,
  offerType,
  selectedOfferProperties,
  ...rest
}) => {
  useEffect(() => {
    window.addEventListener("beforeunload", beforeunload);
    return () => window.removeEventListener("beforeunload", beforeunload);
  }, []);

  const pages = getPages(offerType);

  const currentPage = pages.find(page => page.url === rest.location.pathname);
  if (
    !get(filter, "departure.value") &&
    !get(selectedOffer, "id") &&
    offerType !== OFFER_TYPES.RESOURCE
  ) {
    // code to avoid surfing via url
    return <Redirect to={SEARCH} push />;
  }

  const getPayStripeAction = () => {
    if (offerType === OFFER_TYPES.RESOURCE) {
      return actions.payResourceByStripe;
    }
    if (offerType === OFFER_TYPES.DOCKAGE) {
      return actions.payDockageByStripe;
    }
  };

  const getPayAction = () => {
    if (offerType === OFFER_TYPES.RESOURCE) {
      return actions.payResource;
    }
    if (offerType === OFFER_TYPES.DOCKAGE) {
      return actions.pay;
    }
  };

  const isCheckoutFirstPage =
    currentPage.url === EXTRAS ||
    (currentPage.url === CONTACT_DETAILS && isEmpty(extraList));

  return (
    <>
      <Prompt
        when
        message={(_, action) => {
          if (action === "POP" && isCheckoutFirstPage) {
            return BEFOREUNLOAD_MSG;
          }

          return true;
        }}
      />
      <Route
        {...rest}
        render={matchProps => (
          <div className={classes.root}>
            <Header />
            <div className={classes.background}>
              <Breadcrumb offerType={offerType} {...matchProps} />
              <Title currentPage={currentPage} />
              <Grid container className={classes.page}>
                <Grid className={classes.mainComponent} item xs={12} sm={8}>
                  <Component {...matchProps} />
                </Grid>
                <Grid className={classes.action} item xs={12} sm={4}>
                  <Action
                    isLoading={isLoading}
                    currentPage={currentPage}
                    filter={filter}
                    marina={marina}
                    selectedOffer={selectedOffer}
                    extraList={extraList}
                    offerList={offerList}
                    selectedExtra={selectedExtra}
                    selectedExtraList={selectedExtraList}
                    contactDetail={contactDetail}
                    boatDetail={boatDetail}
                    paymentDetail={paymentDetail}
                    addFilterAction={actions.addFilter}
                    holdAction={actions.holdDockage}
                    holdExtraAction={actions.holdExtra}
                    payAction={getPayAction()}
                    payStripeAction={getPayStripeAction()}
                    selectedOfferProperties={selectedOfferProperties}
                  />
                </Grid>
              </Grid>
            </div>
            <Footer />
          </div>
        )}
      />
    </>
  );
};

OfferLayout.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  filter: PropTypes.object.isRequired,
  marina: PropTypes.object.isRequired,
  selectedOffer: PropTypes.object.isRequired,
  offerList: PropTypes.array,
  extraList: PropTypes.array,
  selectedExtra: PropTypes.array,
  selectedExtraList: PropTypes.array,
  contactDetail: PropTypes.object.isRequired,
  boatDetail: PropTypes.object.isRequired,
  paymentDetail: PropTypes.object
};

OfferLayout.defaultProps = {
  actions: {},
  offerList: [],
  extraList: [],
  selectedOffer: {},
  selectedExtra: [],
  selectedExtraList: [],
  contactDetail: {},
  boatDetail: {},
  marina: {},
  paymentDetail: {}
};

OfferLayout.displayName = "OfferLayout";

export default OfferLayout;
