import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { ReCaptcha } from "react-recaptcha-v3";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import moment from "moment";
import { isEmpty, get } from "lodash";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ReactMarkdown from "react-markdown";
import { loadReCaptcha } from "react-recaptcha-v3";
import { DATE_FORMAT, RECAPTCHA_KEY } from "utils/constants.js";
import { isOnlyNumberValidWith2Decimals } from "utils/validations";
import * as ROUTES from "utils/constants.js";
import shorePowerValues from "redux/offer/shorePowerValues.js";
import * as API from "redux/api";
import axios from "axios";

import { DatePicker, Autocomplete, TextField } from "components/FormFields";

const Search = ({
  actions,
  history,
  classes,
  filter,
  marina,
  location: { data }
}) => {
  const [showPromo, setShowPromo] = useState(false);
  const [isValidToken, setIsValidToken] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(true);
  const dockageType = get(filter, "type.value") || data || "Short-Term";
  const matches = useMediaQuery("(max-height:600px)");

  useEffect(() => {
    loadReCaptcha(RECAPTCHA_KEY);
  }, []);

  useEffect(() => {
    actions.resetOfferStore();
    actions.resetExtraStore();
    actions.add({
      type: { value: dockageType, required: true }
    });
    actions.setOfferTypeAsDockage();
    if (dockageType === "Annual") {
      setDisabled(true);
    }
    if (dockageType === "Seasonal") {
      !isEmpty(get(marina, "data.SeasonStart", "")) &&
        actions.add({
          arrival: {
            value: moment(new Date(get(marina, "data.SeasonStart"))).format(
              DATE_FORMAT
            ),
            required: true
          }
        });
      !isEmpty(get(marina, "data.SeasonEnd", "")) &&
        actions.add({
          departure: {
            value: moment(new Date(get(marina, "data.SeasonEnd"))).format(
              DATE_FORMAT
            ),
            required: true
          }
        });
    }
  }, [actions, dockageType, marina]);

  useEffect(() => {
    let disableValue = true;
    if (
      moment(get(filter, "arrival.value"), DATE_FORMAT).isValid() &&
      moment(get(filter, "departure.value"), DATE_FORMAT).isValid() &&
      !isEmpty(get(filter, "length.value")) &&
      !isEmpty(get(filter, "beam.value")) &&
      !isEmpty(get(filter, "draft.value")) &&
      isOnlyNumberValidWith2Decimals(get(filter, "length.value")) &&
      isOnlyNumberValidWith2Decimals(get(filter, "beam.value")) &&
      isOnlyNumberValidWith2Decimals(get(filter, "draft.value"))
    ) {
      disableValue = false;
    }
    setDisabledSubmit(disableValue);
  }, [filter]);

  const handleChange = ({ id, value, required }) => {
    if (id === "arrival") {
      //validate if arrival is changed, auto-update the departure in the future
      // If annual add 365 days
      if (dockageType === "Annual") {
        actions.add({
          departure: {
            value: moment(value, DATE_FORMAT)
              .add(365, "day")
              .format(DATE_FORMAT),
            required
          }
        });
      } else if (
        get(filter, "departure.value") &&
        moment(value, DATE_FORMAT).isSameOrAfter(
          moment(get(filter, "arrival.value"), DATE_FORMAT)
        )
      ) {
        actions.add({
          departure: {
            value: moment(value, DATE_FORMAT).add(1, "day").format(DATE_FORMAT),
            required
          }
        });
      }
    }
    actions.add({ [id]: { value, required } });
  };

  const showPromoClick = () => {
    setShowPromo(true);
  };

  const verifyCallback = recaptchaToken => {
    axios
      .post(API.siteverify, {
        token: recaptchaToken
      })
      .then(res => {
        const data = res.data;
        setIsValidToken(data.success && data.score >= 0.5);
      })
      .catch(() => {
        setIsValidToken(false);
      });
  };

  const handleClickOpen = () => {
    if (
      get(filter, "length.value") > 300 ||
      get(filter, "beam.value") > 50 ||
      get(filter, "draft.value") > 20
    ) {
      setOpen(true);
    } else {
      submit();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const changeToShortDockage = () => {
    actions.add({
      type: { value: "Short-Term", required: false }
    });
    setDisabled(false);
  };

  const submit = () => {
    history.push(ROUTES.OFFERS);
  };

  const LinkRenderer = props => {
    return (
      <a href={props.href} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    );
  };

  return (
    <Fragment>
      <div className={classes.col2} style={{ top: matches && "5vh" }}>
        <form className={classes.form} noValidate autoComplete="off">
          <Paper className={classes.root} elevation={1}>
            <Typography className={classes.fontTitle} gutterBottom variant="h6">
              {dockageType} Search
            </Typography>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              spacing={matches ? 2 : 4}
            >
              <Grid item xs={12} sm={6} style={{ alignSelf: "flex-start" }}>
                <DatePicker
                  id="arrival"
                  value={get(filter, "arrival.value")}
                  label="Arrival"
                  submitValue={e => handleChange(e)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6} style={{ alignSelf: "flex-start" }}>
                <DatePicker
                  id="departure"
                  value={get(filter, "departure.value")}
                  label="Departure"
                  minDate={moment(get(filter, "arrival.value"), DATE_FORMAT)
                    .add(1, "day")
                    .format(DATE_FORMAT)}
                  submitValue={e => handleChange(e)}
                  disabled={disabled}
                  required
                />
              </Grid>
              {disabled && (
                <div className={classes.departureContainer}>
                  <Typography variant="body1" gutterBottom>
                    Need to alter your departure?{" "}
                    <span
                      className={classes.click}
                      onClick={() => changeToShortDockage()}
                    >
                      Click here
                    </span>
                  </Typography>
                </div>
              )}
              <Grid item xs={12} sm={4}>
                <TextField
                  id="length"
                  label="Length"
                  value={get(filter, "length.value", "")}
                  validate={v => isOnlyNumberValidWith2Decimals(v)}
                  submitValue={e => handleChange(e)}
                  type="number"
                  required
                />
                {get(filter, "length.value", "") && (
                  <div className={classes.lengthContainerGrid}>
                    <Typography
                      variant="body1"
                      gutterBottom
                      className={classes.lengthTypo}
                    >
                      Include your swim platform or bowsprit to ensure your
                      vessel fits.
                    </Typography>
                  </div>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="beam"
                  label="Beam"
                  value={get(filter, "beam.value", "")}
                  validate={v => isOnlyNumberValidWith2Decimals(v)}
                  submitValue={e => handleChange(e)}
                  type="number"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  id="draft"
                  label="Draft"
                  value={get(filter, "draft.value", "")}
                  validate={v => isOnlyNumberValidWith2Decimals(v)}
                  submitValue={e => handleChange(e)}
                  type="number"
                  required
                />
              </Grid>
              {get(filter, "length.value", "") && (
                <div className={classes.lengthContainer}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    className={classes.lengthTypo}
                  >
                    Include your swim platform or bowsprit to ensure your vessel
                    fits.
                  </Typography>
                </div>
              )}
              {get(marina, "data.HasShorePower") && (
                <Grid item xs={12}>
                  <Autocomplete
                    id="shorePower"
                    label="Shore Power (Optional)"
                    value={get(filter, "shorePower.value", 0)}
                    values={shorePowerValues}
                    submitValue={e => handleChange(e)}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                {!showPromo && !get(filter, "groupCode.value") && (
                  <div className={classes.promoLink} onClick={showPromoClick}>
                    Add Group Code
                  </div>
                )}
                {(showPromo || get(filter, "groupCode.value")) && (
                  <TextField
                    id="groupCode"
                    label="Group Code (Optional)"
                    value={get(filter, "groupCode.value", "")}
                    submitValue={e => handleChange(e)}
                  />
                )}
              </Grid>
              <ReCaptcha
                sitekey={RECAPTCHA_KEY}
                action="search"
                verifyCallback={verifyCallback}
              />
              {isValidToken === false && (
                <Typography
                  className={classes.error}
                  variant="body1"
                  gutterBottom
                >
                  Error verifying ReCaptcha, please reload the page and try
                  again!
                </Typography>
              )}

              <Grid item xs={12}>
                <Button
                  id="check availability"
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={e => handleClickOpen(e)}
                  disabled={disabledSubmit || isValidToken === false}
                >
                  Check Availability
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </form>
      </div>
      <div className={classes.col1}>
        <div className={classes.description}>
          <Typography variant="h6" gutterBottom>
            {get(marina, "data.MarinaName")} Online Booking
          </Typography>
          <ReactMarkdown
            source={get(marina, "data.IntroductionTextFirstLine")}
            renderers={{ link: LinkRenderer }}
            escapeHtml={false}
          />
          <ReactMarkdown
            source={get(marina, "data.IntroductionTextSecondLine")}
            renderers={{ link: LinkRenderer }}
            escapeHtml={false}
          />
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Large Dimensions Check
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {get(filter, "length.value") > 300 && (
              <>
                <b>Did you mean to enter a LOA of more than 300 feet?</b>
                <br />
                <br />
              </>
            )}
            {get(filter, "beam.value") > 50 && (
              <>
                <b>Did you mean to enter a Beam of more than 50 feet?</b>
                <br />
                <br />
              </>
            )}
            {get(filter, "draft.value") > 20 && (
              <>
                <b>Did you mean to enter a Draft of more than 20 feet?</b>
                <br />
                <br />
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            No, Fix Search
          </Button>
          <Button onClick={submit} color="primary" autoFocus>
            Yes, Continue Search
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

Search.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  marina: PropTypes.object.isRequired
};

Search.displayName = "Search";

export default Search;
