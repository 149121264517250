import Offer from "./Offer.js";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { getFilters } from "redux/search/selectors.js";
import {
  getOfferList,
  getOfferLoaded,
  getOfferError,
  getSelectedOffer,
  getOfferIsLoading,
  getOfferType,
  getSelectedProperties
} from "redux/offer/selectors.js";
import {
  getSelectedExtra,
  getSelectedExtraList,
  getExtraList
} from "redux/extra/selectors.js";
import { getContactDetails } from "redux/contact/selectors.js";
import { getBoatDetails } from "redux/boat/selectors.js";
import {
  getPaymentIsLoading,
  getPaymentDetails
} from "redux/payment/selectors.js";
import { getMarinaDetails } from "redux/marina/selectors.js";
import { add as addFilter } from "redux/search";
import { holdDockage } from "redux/offer";
import { hold as holdExtra } from "redux/extra";
import {
  pay,
  payResource,
  payDockageByStripe,
  payResourceByStripe
} from "redux/payment";
import styles from "./styles.js";

const mapStateToProps = (state, props) => {
  return {
    filter: getFilters(state),
    marina: getMarinaDetails(state),
    isLoading: getPaymentIsLoading(state) || getOfferIsLoading(state),
    loaded: getOfferLoaded(state),
    error: getOfferError(state),
    selectedOffer: getSelectedOffer(state),
    selectedExtra: getSelectedExtra(state),
    selectedExtraList: getSelectedExtraList(state),
    offerList: getOfferList(state),
    extraList: getExtraList(state),
    contactDetail: getContactDetails(state),
    boatDetail: getBoatDetails(state),
    paymentDetail: getPaymentDetails(state),
    offerType: getOfferType(state),
    selectedOfferProperties: getSelectedProperties(state)
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const actions = {
    addFilter: bindActionCreators(addFilter, dispatch),
    holdDockage: bindActionCreators(holdDockage, dispatch),
    holdExtra: bindActionCreators(holdExtra, dispatch),
    pay: bindActionCreators(pay, dispatch),
    payResource: bindActionCreators(payResource, dispatch),
    payDockageByStripe: bindActionCreators(payDockageByStripe, dispatch),
    payResourceByStripe: bindActionCreators(payResourceByStripe, dispatch)
  };
  return { actions };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(withRouter(Offer)));
