import { isEmpty, get, replace } from "lodash";
import valid from "card-validator";
import aesjs from "aes-js";

const MAIL_REGEX = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/;

export const isMailValid = (value) => {
  return value.match(MAIL_REGEX) !== null ? true : false;
};

export const isOnlyTextValid = (value) => {
  if (!isEmpty(value)) {
    const res = value.match(/[A-Za-z' ]+/g);
    if (res) {
      value = res[0] ? res.join("") : value;
    } else {
      value = "";
    }
  }
  return value;
};

export const isOnlyNumberValid = (value) => {
  if (!isEmpty(value)) {
    const res = value.match(/[0-9 ]+/g);
    if (res) {
      value = res[0] ? res.join("") : value;
    } else {
      value = "";
    }
  }
  return value;
};

export const isOnlyNumberValidWith2Decimals = (value) => {
  const res = value.match(/^(\d{0,3}\.\d{0,2})$|^(\d{0,3})$/g);
  if (res && !isEmpty(value)) {
    return true;
  } else {
    return false;
  }
};

export const isOnlyNumberValidWithDecimalMax300 = (value) => {
  // Regex that accepts from 1 to 299.99
  const res = value.match(
    /^(([1-2]?[0-9]{1,2}\.[0-9]{0,2})|([1-2]?\d{1,2}))$/g
  );
  if (res) {
    return true;
  } else {
    return false;
  }
};

export const isOnlyNumberValidWithDecimalMax50 = (value) => {
  // Regex that accepts from 1 to 49.99
  const res = value.match(/^(([1-4]?[0-9]\.[0-9]{0,2})|([1-4]?\d))$/g);
  if (res) {
    return true;
  } else {
    return false;
  }
};

export const isOnlyNumberValidWithDecimalMax20 = (value) => {
  // Regex that accepts from 1 to 19.99
  const res = value.match(/^(([1]?[0-9]\.[0-9]{0,2})|([1]?\d))$/g);
  if (res) {
    return true;
  } else {
    return false;
  }
};

export const creditCardNumbers = (value) => {
  let ccn = value.split(" ").join("");
  switch (ccn.charAt(0)) {
    case "4":
    case "5":
    case "6":
    case "7":
      ccn =
        ccn.substring(0, 4) +
        " " +
        ccn.substring(4, 8) +
        " " +
        ccn.substring(8, 12) +
        " " +
        ccn.substring(12, ccn.length);
      break;
    case "3":
      ccn =
        ccn.substring(0, 4) +
        " " +
        ccn.substring(4, 10) +
        " " +
        ccn.substring(10, ccn.length);
      break;
    default:
      ccn = "";
      break;
  }
  return ccn.trim();
};

export const creditCardExpiryDate = (value) => {
  value = replace(value, "/", "");
  if (!isEmpty(value)) {
    const res = value.match(/[0-9]+/g);
    if (res) {
      value =
        res[0] && res[0].length > 1
          ? [value.slice(0, 2), "/", value.slice(2)].join("")
          : value;
    } else {
      value = "";
    }
  }
  return value;
};

export const isCreditCardNumberValid = (value) => {
  return valid.number(value).isValid;
};

export const isCardCodeValid = (value, cardCodeSizeValue) => {
  return value.length === parseInt(cardCodeSizeValue);
};

export const isExpirationDateValid = (value) => {
  value = replace(value, "-", "/");
  return valid.expirationDate(value).isValid;
};

export const isPostalCodeValid = (value) => {
  return valid.postalCode(value).isValid;
};

export const getCardCodeSize = (value) => {
  return get(valid.number(value), "card.code.size");
};

export const encrypt = (text) => {
  const key = [17, 5, 9, 4, 5, 6, 7, 8, 9, 1, 7, 18, 13, 1, 3, 16];
  const iv = [21, 2, 5, 5, 25, 13, 27, 28, 29, 31, 6, 15, 21, 34, 35, 14];
  const diff = 64 - text.length;
  for (var i = 0; i < diff; i++) {
    text += "|";
  }
  // const l = text.length;
  const textBytes = aesjs.utils.utf8.toBytes(text);
  const aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
  const encryptedBytes = aesCbc.encrypt(textBytes);
  const encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
  return encryptedHex;
};
