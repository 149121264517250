import axios from "axios";
import * as API from "redux/api";
import { get, replace, upperCase } from "lodash";
import { parseShorePower } from "redux/offer/utils.js";
import { parseExpiryDate } from "redux/payment/utils.js";
import { encrypt } from "utils/validations";

//Action types
const ADD = "ADD_TO_PAYMENT";
const RESET_PAYMENT = "RESET_PAYMENT";

//Functions

// TODO: Refactor all payment request as it has duplicate code

export const pay = ({
  filter,
  selectedOffer,
  selectedExtraList,
  contactDetail,
  boatDetail,
  paymentDetail,
  publicIpV4
}) => {
  return async (dispatch, getState) => {
    const { offer } = getState();

    try {
      dispatch(add({ loading: true }));
      axios
        .post(API.payDockage, {
          OfferHoldID: get(selectedOffer, "offerHoldID"),
          Ip: publicIpV4,
          UserAgent: navigator.userAgent,
          BillingTermsAccepted: get(
            paymentDetail,
            "paymentAuthorization.value",
            false
          ),
          PowerNeed1: parseShorePower(get(filter, "shorePower.value"), 1),
          PowerNeed2: parseShorePower(get(filter, "shorePower.value"), 2),
          PaymentAmount: get(selectedOffer, "minimumPayment"),
          Boat: {
            BoatName: get(boatDetail, "boatName.value"),
            Make: get(boatDetail, "make.value"),
            Model: get(boatDetail, "model.value"),
            Color: get(boatDetail, "color.value"),
            Length:
              get(boatDetail, "length.value") &&
              parseInt(get(boatDetail, "length.value"), 10),
            Beam:
              get(boatDetail, "beam.value") &&
              parseInt(get(boatDetail, "beam.value"), 10),
            Draft:
              get(boatDetail, "draft.value") &&
              parseInt(get(boatDetail, "draft.value"), 10),
            PowerNeed1: parseShorePower(get(boatDetail, "shorePower.value"), 1),
            PowerNeed2: parseShorePower(get(boatDetail, "shorePower.value"), 2)
          },
          Contact: {
            FirstName: get(contactDetail, "firstName.value"),
            LastName: get(contactDetail, "lastName.value"),
            Email: get(contactDetail, "email.value"),
            Address1: get(contactDetail, "address1.value"),
            Address2: get(contactDetail, "address2.value"),
            City: get(contactDetail, "city.value"),
            State: get(contactDetail, "province.value"),
            Country: get(contactDetail, "country.value"),
            PostalCode: get(contactDetail, "postalCode.value"),
            HomePhone: replace(get(contactDetail, "homePhone.value"), "+", ""),
            MobilePhone: replace(
              get(contactDetail, "mobilePhone.value"),
              "+",
              ""
            )
          },
          Insurance: {
            InsuranceProvider: get(boatDetail, "insuranceProvider.value"),
            LiablilityMaximum: get(boatDetail, "liabilityMaximun.value"),
            EffectiveDate: get(boatDetail, "effectiveDate.value"),
            ExpirationDate: get(boatDetail, "expirationDate.value")
          },
          Extras: selectedExtraList.map(i => ({
            Id: i.id,
            AddonName: i.addonName,
            Required: i.required,
            PricingMethod: i.pricingMethod,
            UnitPrice: i.unitPrice,
            Total: i.total,
            Description: i.description
          })),
          CreditCard: {
            CardOwnerName: encrypt(get(paymentDetail, "cardName.value")),
            CardNumber: encrypt(get(paymentDetail, "cardNumber.value")),
            Expiry: parseExpiryDate(get(paymentDetail, "expiryDate.value")),
            CCV: encrypt(get(paymentDetail, "ccv.value")),
            PostalCode: get(paymentDetail, "postalCode.value")
          },
          OnlineBookingNotes: get(offer, "userNote")
            ? get(offer, "userNote")
            : undefined
        })
        .then(res => {
          if (res.data.Success !== false) {
            dispatch(add({ bookingReference: res.data.BookingReference }));
            dispatch(add({ loading: false }));
          } else {
            dispatch(add({ bookingReference: "error" }));
            dispatch(add({ error: res.data.Message }));
          }
        })
        .catch(error => {
          dispatch(add({ bookingReference: "error" }));
          dispatch(add({ error: error.response.data.Message }));
        })
        .finally(() => dispatch(add({ loading: false })));
    } catch (error) {
      dispatch(add({ error: error }));
      dispatch(add({ loading: false }));
    }
  };
};

export const payDockageByStripe = ({
  filter,
  selectedOffer,
  selectedExtraList,
  contactDetail,
  boatDetail,
  paymentDetail,
  stripe,
  card,
  publicIpV4
}) => {
  return async (dispatch, getState) => {
    const { offer } = getState();
    try {
      dispatch(add({ loading: true }));
      stripe
        .createPaymentMethod({
          type: "card",
          card: card,
          billing_details: {
            address: {
              postal_code: get(paymentDetail, "postalCode.value")
            }
          }
        })
        .then(res => {
          dispatch(add({ paymentMethod: res.paymentMethod }));
          axios
            .post(API.payDockage, {
              OfferHoldID: get(selectedOffer, "offerHoldID"),
              Ip: publicIpV4,
              UserAgent: navigator.userAgent,
              BillingTermsAccepted: get(
                paymentDetail,
                "paymentAuthorization.value",
                false
              ),
              PowerNeed1: parseShorePower(get(filter, "shorePower.value"), 1),
              PowerNeed2: parseShorePower(get(filter, "shorePower.value"), 2),
              PaymentAmount: get(selectedOffer, "minimumPayment"),
              Boat: {
                BoatName: get(boatDetail, "boatName.value"),
                Make: get(boatDetail, "make.value"),
                Model: get(boatDetail, "model.value"),
                Color: get(boatDetail, "color.value"),
                Length:
                  get(boatDetail, "length.value") &&
                  parseInt(get(boatDetail, "length.value"), 10),
                Beam:
                  get(boatDetail, "beam.value") &&
                  parseInt(get(boatDetail, "beam.value"), 10),
                Draft:
                  get(boatDetail, "draft.value") &&
                  parseInt(get(boatDetail, "draft.value"), 10),
                // PowerNeed1: get(boatDetail, "shorePower1.value"),
                PowerNeed1: parseShorePower(
                  get(boatDetail, "shorePower.value"),
                  1
                ),
                // PowerNeed2: get(boatDetail, "shorePower2.value")
                PowerNeed2: parseShorePower(
                  get(boatDetail, "shorePower.value"),
                  2
                )
              },
              Contact: {
                FirstName: get(contactDetail, "firstName.value"),
                LastName: get(contactDetail, "lastName.value"),
                Email: get(contactDetail, "email.value"),
                Address1: get(contactDetail, "address1.value"),
                Address2: get(contactDetail, "address2.value"),
                City: get(contactDetail, "city.value"),
                State: get(contactDetail, "province.value"),
                Country: get(contactDetail, "country.value"),
                PostalCode: get(contactDetail, "postalCode.value"),
                HomePhone: replace(
                  get(contactDetail, "homePhone.value"),
                  "+",
                  ""
                ),
                MobilePhone: replace(
                  get(contactDetail, "mobilePhone.value"),
                  "+",
                  ""
                )
              },
              Insurance: {
                InsuranceProvider: get(boatDetail, "insuranceProvider.value"),
                LiablilityMaximum: get(boatDetail, "liabilityMaximun.value"),
                EffectiveDate: get(boatDetail, "effectiveDate.value"),
                ExpirationDate: get(boatDetail, "expirationDate.value")
              },
              Extras: selectedExtraList.map(i => ({
                Id: i.id,
                AddonName: i.addonName,
                Required: i.required,
                PricingMethod: i.pricingMethod,
                UnitPrice: i.unitPrice,
                Total: i.total,
                Description: i.description
              })),
              CreditCard: {},
              PaymentMethodId: res.paymentMethod.id,
              Brand: upperCase(res.paymentMethod.card.brand),
              Last4: res.paymentMethod.card.last4,
              OnlineBookingNotes: get(offer, "userNote")
                ? get(offer, "userNote")
                : undefined
            })
            .then(res => {
              if (res.data.Success !== false) {
                dispatch(add({ bookingReference: res.data.BookingReference }));
                dispatch(add({ loading: false }));
              } else {
                dispatch(add({ bookingReference: "error" }));
                dispatch(add({ error: res.data.Message }));
              }
            })
            .catch(error => {
              dispatch(add({ bookingReference: "error" }));
              dispatch(add({ error: error.response.data.Message }));
            })
            .finally(() => dispatch(add({ loading: false })));
        })
        .catch(error => {
          dispatch(add({ bookingReference: "error" }));
          dispatch(add({ error: "Stripe validation error. Try again." }));
          dispatch(add({ loading: false }));
        });
    } catch (error) {
      dispatch(add({ error: error }));
      dispatch(add({ loading: false }));
    }
  };
};

export const payResource = ({
  selectedOffer,
  selectedExtraList,
  contactDetail,
  paymentDetail,
  publicIpV4
}) => {
  return async (dispatch, getState) => {
    const { offer } = getState();

    try {
      dispatch(add({ loading: true }));
      axios
        .post(API.payResource, {
          ResourceAssignmentId: get(
            getState(),
            "offer.hold.resourceAssignmentId"
          ),
          Ip: publicIpV4,
          UserAgent: navigator.userAgent,
          BillingTermsAccepted: get(
            paymentDetail,
            "paymentAuthorization.value",
            false
          ),
          PaymentAmount: get(selectedOffer, "minimumPayment"),
          Contact: {
            FirstName: get(contactDetail, "firstName.value"),
            LastName: get(contactDetail, "lastName.value"),
            Email: get(contactDetail, "email.value"),
            Address1: get(contactDetail, "address1.value"),
            Address2: get(contactDetail, "address2.value"),
            City: get(contactDetail, "city.value"),
            State: get(contactDetail, "province.value"),
            Country: get(contactDetail, "country.value"),
            PostalCode: get(contactDetail, "postalCode.value"),
            HomePhone: replace(get(contactDetail, "homePhone.value"), "+", ""),
            MobilePhone: replace(
              get(contactDetail, "mobilePhone.value"),
              "+",
              ""
            )
          },
          Extras: selectedExtraList.map(i => ({
            Id: i.id,
            AddonName: i.addonName,
            Required: i.required,
            PricingMethod: i.pricingMethod,
            UnitPrice: i.unitPrice,
            Total: i.total,
            Description: i.description
          })),
          CreditCard: {
            CardOwnerName: encrypt(get(paymentDetail, "cardName.value")),
            CardNumber: encrypt(get(paymentDetail, "cardNumber.value")),
            Expiry: parseExpiryDate(get(paymentDetail, "expiryDate.value")),
            CCV: encrypt(get(paymentDetail, "ccv.value")),
            PostalCode: get(paymentDetail, "postalCode.value")
          },
          OnlineBookingNotes: get(offer, "userNote")
            ? get(offer, "userNote")
            : undefined
        })
        .then(res => {
          if (res.data.Success !== false) {
            dispatch(add({ bookingReference: res.data.BookingReference }));
            dispatch(add({ loading: false }));
          } else {
            dispatch(add({ bookingReference: "error" }));
            dispatch(add({ error: res.data.Message }));
          }
        })
        .catch(error => {
          dispatch(add({ bookingReference: "error" }));
          dispatch(add({ error: error.response.data.Message }));
        })
        .finally(() => dispatch(add({ loading: false })));
    } catch (error) {
      dispatch(add({ error: error }));
      dispatch(add({ loading: false }));
    }
  };
};

export const payResourceByStripe = ({
  selectedOffer,
  selectedExtraList,
  contactDetail,
  paymentDetail,
  stripe,
  card,
  publicIpV4
}) => {
  return async (dispatch, getState) => {
    const { offer } = getState();

    try {
      dispatch(add({ loading: true }));
      stripe
        .createPaymentMethod({
          type: "card",
          card: card,
          billing_details: {
            address: {
              postal_code: get(paymentDetail, "postalCode.value")
            }
          }
        })
        .then(res => {
          dispatch(add({ paymentMethod: res.paymentMethod }));

          axios
            .post(API.payResource, {
              ResourceAssignmentId: get(
                getState(),
                "offer.hold.resourceAssignmentId"
              ),
              Ip: publicIpV4,
              UserAgent: navigator.userAgent,
              BillingTermsAccepted: get(
                paymentDetail,
                "paymentAuthorization.value",
                false
              ),
              PaymentAmount: get(selectedOffer, "minimumPayment"),
              Contact: {
                FirstName: get(contactDetail, "firstName.value"),
                LastName: get(contactDetail, "lastName.value"),
                Email: get(contactDetail, "email.value"),
                Address1: get(contactDetail, "address1.value"),
                Address2: get(contactDetail, "address2.value"),
                City: get(contactDetail, "city.value"),
                State: get(contactDetail, "province.value"),
                Country: get(contactDetail, "country.value"),
                PostalCode: get(contactDetail, "postalCode.value"),
                HomePhone: replace(
                  get(contactDetail, "homePhone.value"),
                  "+",
                  ""
                ),
                MobilePhone: replace(
                  get(contactDetail, "mobilePhone.value"),
                  "+",
                  ""
                )
              },
              Extras: selectedExtraList.map(i => ({
                Id: i.id,
                AddonName: i.addonName,
                Required: i.required,
                PricingMethod: i.pricingMethod,
                UnitPrice: i.unitPrice,
                Total: i.total,
                Description: i.description
              })),
              PaymentMethodId: res.paymentMethod.id,
              OnlineBookingNotes: get(offer, "userNote")
                ? get(offer, "userNote")
                : undefined
            })
            .then(res => {
              if (res.data.Success !== false) {
                dispatch(add({ bookingReference: res.data.BookingReference }));
                dispatch(add({ loading: false }));
              } else {
                dispatch(add({ bookingReference: "error" }));
                dispatch(add({ error: res.data.Message }));
              }
            })
            .catch(error => {
              dispatch(add({ bookingReference: "error" }));
              dispatch(add({ error: error.response.data.Message }));
            })
            .finally(() => dispatch(add({ loading: false })));
        })
        .catch(error => {
          dispatch(add({ bookingReference: "error" }));
          dispatch(add({ error: "Stripe validation error. Try again." }));
          dispatch(add({ loading: false }));
        });
    } catch (error) {
      dispatch(add({ error: error }));
      dispatch(add({ loading: false }));
    }
  };
};

//Action creators
export function add(payload) {
  return {
    type: ADD,
    payload
  };
}

export function resetCreditCardStore() {
  return {
    type: RESET_PAYMENT
  };
}

//Reducers
const initialState = {
  loading: false,
  bookingReference: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADD:
      return {
        ...state,
        ...action.payload
      };
    case RESET_PAYMENT:
      return { ...initialState };
    case "RESET":
      return initialState;
    default:
      return state;
  }
};
