import React, { useMemo } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import Paper from "@material-ui/core/Paper";
import { DATE_FORMAT } from "utils/constants.js";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import { getNumberWith2Decimals } from "utils/helper.js";
import { OFFER_TYPES } from "utils/constants";

const SummaryBox = ({
  classes,
  filterTitle,
  filterArrival,
  filterDeparture,
  offer,
  extras,
  selectedExtraPrice,
  selectedExtraTax
}) => {
  const extraTotalPrice = parseFloat(selectedExtraPrice, 10);
  const extraTotalTax = parseFloat(selectedExtraTax, 10);
  const diffDays = moment(filterDeparture, DATE_FORMAT).diff(
    moment(filterArrival, DATE_FORMAT),
    "days"
  );
  const night = diffDays > 1 ? "nights" : "night";
  const daysCaption = `${diffDays} ${night}`;
  const getOfferSummaryData = () => {
    let uniqueData;
    if (offer.type === OFFER_TYPES.DOCKAGE) {
      uniqueData = {
        nightPrice: getNumberWith2Decimals(
          (offer.discountedAverageNightlyRate
            ? offer.discountedAverageNightlyRate
            : offer.averageNightlyRate) * diffDays
        ),
        subtotal: getNumberWith2Decimals(offer.offerSubtotal + extraTotalPrice)
      };
    }

    if (offer.type === OFFER_TYPES.RESOURCE) {
      uniqueData = {
        nightPrice: offer.rateSubtotal,
        subtotal: getNumberWith2Decimals(
          offer.rateSubtotal + offer.addOnSubtotal + extraTotalPrice
        )
      };
    }

    return {
      ...uniqueData,
      totalTax: getNumberWith2Decimals(offer.totalTax + extraTotalTax),
      total: getNumberWith2Decimals(
        offer.offerTotal + extraTotalPrice + extraTotalTax
      ),
      minimumPayment: getNumberWith2Decimals(offer.minimumPayment)
    };
  };

  const summaryData = useMemo(getOfferSummaryData, []);

  return (
    <Paper className={classes.paper} elevation={1}>
      <div className={classes.row}>
        <div className={classes.item}>
          <div className={classes.itemName}>
            <Typography className={classes.title} variant="body1">
              {`${filterTitle} (${daysCaption} from ${filterArrival} to ${filterDeparture})`}
            </Typography>
          </div>
          <div className={classes.itemPrice}>{`$${summaryData.nightPrice}`}</div>
        </div>
        {extras.map(i => {
          return (
            <div key={i.id} className={classes.item}>
              <div className={classes.itemName}>
                <Typography className={classes.title} variant="body1">
                  {i.addonName}
                </Typography>
              </div>
              <div className={classes.itemPrice}>{`$${i.total}`}</div>
            </div>
          );
        })}
        <Divider className={classes.divider} />
        <div className={classes.item}>
          <div className={classes.itemName}>
            <Typography className={classes.title} variant="body1">
              Subtotal
            </Typography>
          </div>
          <div className={classes.itemPrice}>{`$${summaryData.subtotal}`}</div>
        </div>
        <div className={classes.item}>
          <div className={classes.itemName}>
            <Typography className={classes.title} variant="body1">
              Tax
            </Typography>
          </div>
          <div className={classes.itemPrice}>{`$${summaryData.totalTax}`}</div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.item}>
          <div className={classes.itemName}>
            <Typography className={classes.title} variant="body1">
              Total
            </Typography>
          </div>
          <div className={classes.itemPrice}>{`$${summaryData.total}`}</div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.item}>
          <div className={classes.itemName}>
            <Typography className={classes.title} variant="body1">
              Minimum Payment
            </Typography>
          </div>
          <div className={classes.itemPrice}>{`$${summaryData.minimumPayment}`}</div>
        </div>
      </div>
    </Paper>
  );
};

SummaryBox.propTypes = {
  classes: PropTypes.object,
  filterTitle: PropTypes.string.isRequired,
  filterArrival: PropTypes.string.isRequired,
  filterDeparture: PropTypes.string.isRequired,
  offer: PropTypes.object.isRequired,
  extras: PropTypes.array,
  selectedExtraPrice: PropTypes.number,
  selectedExtraTax: PropTypes.number
};

SummaryBox.defaultProps = {
  extras: [],
  selectedExtraPrice: 0,
  selectedExtraTax: 0
};

SummaryBox.displayName = "SummaryBox";

export default SummaryBox;
