import { useCallback, useMemo } from "react";
import { isNil } from "lodash";
import { CUSTOM_FIELDS } from "utils/constants";
import * as yup from "yup";
import DatePicker from "components/NewSite/FormFields/DatePicker";
import Input from "components/NewSite/FormFields/Input";
import Select from "components/NewSite/FormFields/Select";
import Multiselect from "components/NewSite/FormFields/Multiselect";
import { get, isEmpty, isArray } from "lodash";

const useDynamicForm = (customFields, defaultValues, dateFormat) => {
  const getDynamicForm = useCallback(
    fields => {
      return fields.reduce(
        (acc, cur) => {
          const errorMessage = `${cur.label} is a required field.`;

          const fieldGroup = cur.required ? acc.required : acc.optional;

          // TODO: Fix same constant name forbidden in switch

          switch (cur.type) {
            case CUSTOM_FIELDS.DATE:
              acc.validation[cur.id] = cur.required
                ? yup.date().required(errorMessage)
                : yup.date().notRequired();

              const dateInitialValue = get(
                defaultValues[cur.id],
                "value",
                new Date()
              );

              acc.initialValues[cur.id] = dateInitialValue;

              fieldGroup.push({
                component: DatePicker,
                props: {
                  label: cur.label,
                  minDate: new Date().toISOString(),
                  id: cur.id,
                  format: dateFormat,
                  required: cur.required
                },
                initialValue: dateInitialValue,
                id: cur.id,
                required: cur.required,
                type: cur.type
              });

              return acc;

            case CUSTOM_FIELDS.MULTISELECT:
              acc.validation[cur.id] = cur.required
                ? yup
                    .array()
                    .required(errorMessage)
                    .test({
                      message: `${cur.label} requires to choose at least one option`,
                      test: arr => arr.length >= 1
                    })
                : yup.array().notRequired();

              const mSelectInitialValue = get(
                defaultValues,
                [cur.id, "value"],
                []
              );

              acc.initialValues[cur.id] = mSelectInitialValue;

              fieldGroup.push({
                component: Multiselect,
                props: {
                  label: cur.label,
                  id: cur.id,
                  options: cur.options,
                  required: cur.required
                },
                initialValue: mSelectInitialValue,
                id: cur.id,
                required: cur.required,
                type: cur.type
              });

              return acc;

            case CUSTOM_FIELDS.NUMBER:
              acc.validation[cur.id] = cur.required
                ? yup.number().required(errorMessage)
                : yup.number().notRequired();

              const numberInitialValue = get(
                defaultValues,
                [cur.id, "value"],
                ""
              );

              acc.initialValues[cur.id] = numberInitialValue;

              fieldGroup.push({
                component: Input,
                props: {
                  label: cur.label,
                  type: cur.type,
                  id: cur.id,
                  required: cur.required
                },
                initialValue: numberInitialValue,
                id: cur.id,
                required: cur.required,
                type: cur.type
              });

              return acc;

            case CUSTOM_FIELDS.SELECT:
              acc.validation[cur.id] = cur.required
                ? yup.string().required(errorMessage)
                : yup.string().notRequired();

              const selectInitialValue = get(
                defaultValues,
                [cur.id, "value"],
                ""
              );

              acc.initialValues[cur.id] = selectInitialValue;

              fieldGroup.push({
                component: Select,
                props: {
                  label: cur.label,
                  id: cur.id,
                  options: cur.options,
                  required: cur.required
                },
                initialValue: selectInitialValue,
                id: cur.id,
                required: cur.required,
                type: cur.type
              });

              return acc;

            case CUSTOM_FIELDS.TEXT:
              acc.validation[cur.id] = cur.required
                ? yup.string().required(errorMessage)
                : yup.string().notRequired();

              const textInitialValue = get(
                defaultValues,
                [cur.id, "value"],
                ""
              );

              acc.initialValues[cur.id] = textInitialValue;

              fieldGroup.push({
                component: Input,
                props: {
                  label: cur.label,
                  type: cur.type,
                  id: cur.id,
                  required: cur.required
                },
                initialValue: textInitialValue,
                id: cur.id,
                required: cur.required,
                type: cur.type
              });

              return acc;

            default:
              acc.initialValues[cur.id] = "";

              acc.validation[cur.id] = cur.required
                ? yup.mixed().required(errorMessage)
                : yup.mixed().notRequired();

              return acc;
          }
        },
        { initialValues: {}, validation: {}, required: [], optional: [] }
      );
    },
    [defaultValues, dateFormat]
  );

  const {
    initialValues,
    required = [],
    optional = [],
    validation
  } = useMemo(() => {
    return isNil(customFields) ? {} : getDynamicForm(customFields);
  }, [customFields, getDynamicForm]);

  const allFields = [...required, ...optional];

  const parseCustomFieldsValues = values => {
    return Object.keys(values).reduce((acc, id) => {
      const field = allFields.find(i => i.id === id);

      if(isArray(values[id]) && isEmpty(values[id])) {
        return acc;
      }

      if (field && values[id]) {
        acc[id] = {
          type: field.type,
          value: values[id]
        };
      }

      return acc;
    }, {});
  };

  return {
    initialValues,
    optional,
    required,
    allFields,
    validation,
    parseCustomFieldsValues
  };
};

export default useDynamicForm;
