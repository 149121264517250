import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { isEmpty, includes } from "lodash";
import { Redirect } from "react-router-dom";
import OfferBox from "components/Shared/OfferBox";
import Error from "components/Shared/Error";
import SplashScreen from "components/Shared/SplashScreen";
import * as ROUTES from "utils/constants.js";

const Extra = ({
  classes,
  actions,
  isLoading,
  holded,
  error,
  search,
  filter,
  extraList,
  selectedExtra
}) => {
  const requiredFields = extraList
    .filter(i => i.required === true)
    .map(i => i.id)
    .join(",");

  useEffect(() => {
    if (isEmpty(selectedExtra)) {
      // Filling the required extra fields
      actions.select(requiredFields);
    }
    return () => actions.add({ holded: false });
  }, [actions, extraList, requiredFields, selectedExtra]);

  const handleSelect = id => {
    if (includes(requiredFields, id)) {
      return;
    }
    actions.select(id);
  };

  if (isLoading) return <SplashScreen animation="lighthouse" />;
  if (holded) {
    return <Redirect to={ROUTES.CONTACT_DETAILS} push />;
  }
  return (
    <div className={classes.container}>
      {error && <Error message={error} />}
      {extraList.map((item, i) => {
        return (
          <OfferBox
            id={item.id}
            key={item.id}
            selected={selectedExtra.find(a => a === item.id)}
            title={item.addonName}
            price={item.unitPrice}
            priceMsg={item.pricingMethod}
            description={item.description}
            showCalendar={false}
            // warnings={item.warnings}
            required={item.required}
            handleSelect={handleSelect}
          />
        );
      })}
    </div>
  );
};

Extra.propTypes = {
  classes: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  holded: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  search: PropTypes.func,
  filter: PropTypes.object.isRequired,
  extraList: PropTypes.array.isRequired,
  selectedExtra: PropTypes.array.isRequired
};

Extra.displayName = "Extra";

export default React.memo(Extra);
