import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Routes from "./App.routes.js";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { getName } from "utils/helper.js";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import styles from "./styles.js";
import TagManager from "react-gtm-module";
import FullStory from "react-fullstory";

const App = ({ match, actions, location: { pathname } }) => {
  const [color, setColor] = useState("#1e6286");
  const name = getName(pathname);
  TagManager.initialize({
    gtmId: window["runtime"].gtmKey
  });
  const ORG_ID = window["runtime"].fullstoryId;
  const STRIPE_ID = window["runtime"].stripeId;

  const stripePromise = loadStripe(STRIPE_ID);
  useEffect(() => {
    actions.searchMarina(name);
    const color = "#1e6286";
    setColor(color);
  }, [actions, name]);
  if (!color) return null;

  styles.palette.primary.main = color;

  return (
    <Elements stripe={stripePromise}>
      <MuiThemeProvider theme={createMuiTheme(styles)}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <FullStory org={ORG_ID} />
          <Routes companyName={getName(pathname)} />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </Elements>
  );
};

App.propTypes = {
  actions: PropTypes.object.isRequired
};

App.displayName = "App";

export default App;
