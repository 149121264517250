export const XSMALL = "xs"; //xs, extra-small: 0px or larger
export const SMALL = "sm"; //sm, small: 600px or larger
export const MEDIUM = "md"; //md, medium: 960px or larger
export const LARGE = "lg"; //lg, large: 1280px or larger
export const XLARGE = "xl"; //xl, extra-large: 1920px or larger

export const HOME = "/home";
export const SEARCH = "/search";
export const OFFERS = "/offers";
export const EXTRAS = "/extras";
export const CONTACT_DETAILS = "/contact-details";
export const BOAT_DETAILS = "/boat-details";
export const PAYMENT = "/payment";
export const BOOKING_COMPLETE = "/booking-complete";
export const NO_RESULTS = "/no-results";
export const BUSINESS_LIST = "/business-list";
export const RESOURCES = "/resources";

export const DATE_FORMAT = "MM/DD/YYYY";

export const PAGES = {
  DOCKAGE: [
    {
      value: "Offers",
      url: `${OFFERS}`,
      nextUrl: `${EXTRAS}`
    },
    {
      value: "Extras",
      url: `${EXTRAS}`,
      nextUrl: `${CONTACT_DETAILS}`
    },
    {
      value: "Contact Details",
      url: `${CONTACT_DETAILS}`,
      nextUrl: `${BOAT_DETAILS}`
    },
    { value: "Boat Details", url: `${BOAT_DETAILS}`, nextUrl: `${PAYMENT}` },
    {
      value: "Payment Details",
      url: `${PAYMENT}`,
      nextUrl: `${BOOKING_COMPLETE}`
    },
    {
      value: "Booking Complete",
      url: `${BOOKING_COMPLETE}`,
      nextUrl: `${HOME}`
    }
  ],
  RESOURCE: [
    {
      value: "Home",
      url: `${HOME}`,
      nextUrl: ""
    },
    {
      value: "Extras",
      url: `${EXTRAS}`,
      nextUrl: `${CONTACT_DETAILS}`
    },
    {
      value: "Contact Details",
      url: `${CONTACT_DETAILS}`,
      nextUrl: `${PAYMENT}`
    },
    {
      value: "Payment Details",
      url: `${PAYMENT}`,
      nextUrl: `${BOOKING_COMPLETE}`
    },
    {
      value: "Booking Complete",
      url: `${BOOKING_COMPLETE}`,
      nextUrl: `${HOME}`
    }
  ]
};

export const RECAPTCHA_KEY = "6LcensAUAAAAAOLdBQyC0qN3xEH3QGeF2udAf8a7";

export const OFFER_TYPES = Object.freeze({
  DOCKAGE: "DOCKAGE",
  RESOURCE: "RESOURCE"
});

export const CUSTOM_FIELDS = Object.freeze({
  TEXT: "text",
  NUMBER: "number",
  SELECT: "select",
  MULTISELECT: "multiselect",
  DATE: "date"
});
