import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Link as MUILink } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Hidden from "@material-ui/core/Hidden";
import CircularProgress from "@material-ui/core/CircularProgress";
import { replace } from "lodash";
import { isMailValid, isOnlyTextValid } from "utils/validations";
import { isValidPhoneNumber } from "react-phone-number-input";
import { TextField, PhoneInput } from "components/FormFields";
import { setUserVars } from "react-fullstory";
import * as ROUTES from "utils/constants.js";

const NoResults = ({
  classes,
  actions,
  offerPostLogSetId,
  isLoading,
  isLoaded,
  marina,
  data
}) => {
  const [mail, setMail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const handleChangeMail = ({ value }) => {
    setMail(value);
  };
  const handleChangeName = ({ value }) => {
    setName(value);
  };
  const handleChangePhone = ({ value }) => {
    setPhone(value);
  };
  const submit = () => {
    setUserVars({
      displayName: name,
      email: mail
    });
    actions.submit({
      name,
      mail,
      phone: replace(phone, "+", ""),
      offerPostLogSetId
    });
  };

  const showDisable = () => {
    if (
      !isMailValid(mail) ||
      !isValidPhoneNumber(phone) ||
      !isOnlyTextValid(name) ||
      (!isLoading && isLoaded) ||
      isLoading
    )
      return true;
    return false;
  };

  return (
    <form className={classes.container} noValidate autoComplete="off">
      <Paper className={classes.paper} elevation={1}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              No space is available for the dates and dimensions you requested
              but {marina.MarinaName} may be able to still accommodate you.
            </Typography>
            <Typography variant="body1" gutterBottom>
              Please provide your email and phone number below so{" "}
              {marina.MarinaName} can get in touch.
            </Typography>
          </Grid>
          <Grid className={classes.grid} container direction="row" spacing={4}>
            <Grid item xs={12}>
              <TextField
                id="name"
                label="Full Name"
                value={name}
                submitValue={e => handleChangeName(e)}
                validate={v => isOnlyTextValid(v)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                id="email"
                label="Email"
                value={mail}
                submitValue={e => handleChangeMail(e)}
                validate={v => isMailValid(v)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PhoneInput
                id="phone"
                label="Phone"
                placeHolder="Phone number"
                value={phone}
                validate={v => isValidPhoneNumber(v)}
                submitValue={e => handleChangePhone(e)}
                required={!isValidPhoneNumber(phone)}
              />
            </Grid>
            {data && (
              <Grid item xs={12}>
                <div className={classes.submitted}>
                  <Typography variant="h6" className={classes.success}>
                    A message to the business has been sent!
                  </Typography>
                  <MUILink
                    component={Link}
                    variant="body1"
                    color="primary"
                    to={`${ROUTES.HOME}`}
                  >
                    Click here to search again.
                  </MUILink>
                </div>
              </Grid>
            )}
            {!data && isLoaded && (
              <Grid item xs={12}>
                <div className={classes.submittedWithError}>
                  <Typography variant="h6" className={classes.error}>
                    Opss! Something went wrong!
                  </Typography>
                  <MUILink
                    component={Link}
                    variant="body1"
                    color="primary"
                    to={`${ROUTES.HOME}`}
                  >
                    Click here to search again.
                  </MUILink>
                </div>
              </Grid>
            )}
            <Hidden only="xs">
              <Grid item sm={8} />
            </Hidden>
            <Grid item xs={12} sm={4}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                disabled={showDisable()}
                onClick={() => submit()}
              >
                Submit
                {isLoading && (
                  <CircularProgress
                    style={{ right: "10%", position: "absolute" }}
                    size={15}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </form>
  );
};

NoResults.propTypes = {
  classes: PropTypes.object,
  marina: PropTypes.object.isRequired,
  offerPostLogSetId: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  data: PropTypes.bool.isRequired,
  actions: PropTypes.object.isRequired
};

NoResults.defaultProps = {};

NoResults.displayName = "NoResults";

export default NoResults;
