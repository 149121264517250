import { isEmpty } from "lodash";
import shorePowerValues from "./shorePowerValues.js";
import rvExample from "assets/img/rv-example.jpeg";
import { get, toNumber } from "lodash";
import { CUSTOM_FIELDS } from "utils/constants.js";

export const parseOffers = data =>
  (get(data, "Offers", []) || []).map(parseOffer);

export const parseOffer = ({
  Description = null,
  EarliestArrival = null,
  FilestackFiles = null,
  LatestDeparture = null,
  EnableNightly = null,
  AverageNightlyRate = null,
  CustomerDescription = null,
  Name = null,
  CustomerOfferName = null,
  DiscountAmount = null,
  DiscountMethod = null,
  DiscountedAverageNightlyRate = null,
  DiscountedNightlyRate = null,
  Extras = null,
  MaximumDurationWarning = null,
  MinimumDurationWarning = null,
  MinimumPayment = null,
  OfferID = null,
  OfferId = null,
  OfferSubtotal = null,
  OfferTotal = null,
  OfferType = null,
  PriceMethod = null,
  Rates = null,
  ShorePowerWarning = null,
  SpacesRemaining = null,
  StandardNightlyRate = null,
  StandardUnitPrice = null,
  TermsAndConditions = null,
  Terms = null,
  TotalTax = null,
  UpdatedDepartureDate = null,
  OfferLengthOverride = null,
  PriceBlocks = null,
  AddOns = null,
  RateSubtotal = null,
  AddOnSubtotal = null,
  Taxes = null,
  Total = null,
  AvailableResources = null,
  SecurityDeposit = null,
  RequireSpaceSelection = null,
  Spaces = null
}) => ({
  averageNightlyRate: AverageNightlyRate,
  customerDescription: CustomerDescription,
  customerOfferName: CustomerOfferName,
  discountAmount: DiscountAmount,
  discountMethod: DiscountMethod,
  discountedAverageNightlyRate: DiscountedAverageNightlyRate,
  discountedNightlyRate: DiscountedNightlyRate,
  extras: Extras || AddOns,
  maximumDurationWarning: MaximumDurationWarning,
  minimumDurationWarning: MinimumDurationWarning,
  minimumPayment: MinimumPayment,
  offerID: OfferID || OfferId,
  offerSubtotal: OfferSubtotal,
  rateSubtotal: RateSubtotal,
  offerTotal: OfferTotal || Total,
  offerType: OfferType,
  priceMethod: PriceMethod,
  rates: Rates,
  shorePowerWarning: ShorePowerWarning,
  spacesRemaining: SpacesRemaining,
  standardNightlyRate: StandardNightlyRate,
  standardUnitPrice: StandardUnitPrice,
  termsAndConditions: TermsAndConditions,
  Terms: Terms,
  totalTax: TotalTax || Taxes,
  updatedDepartureDate: UpdatedDepartureDate,
  description: Description,
  earliestArrival: EarliestArrival,
  filestackFiles: FilestackFiles,
  latestDeparture: LatestDeparture,
  enableNightly: EnableNightly,
  name: Name,
  photos: [rvExample, rvExample, rvExample, rvExample, rvExample, rvExample],
  primaryPhoto: rvExample,
  offerLengthOverride: OfferLengthOverride,
  priceBlocks: PriceBlocks,
  addOnSubtotal: AddOnSubtotal,
  availableResources: AvailableResources,
  securityDeposit: SecurityDeposit,
  requireSpaceSelection: RequireSpaceSelection,
  spaces: Spaces
});

export const parseShorePower = (shorePowerId, motorNumber) => {
  const shorePower = shorePowerValues.find(item => item.value === shorePowerId);
  if (isEmpty(shorePower)) return "";
  if (motorNumber === 0) return shorePower.value;
  if (motorNumber === 1)
    return !isEmpty(shorePower.PowerNeed1) ? shorePower.PowerNeed1 : "";
  if (motorNumber === 2)
    return !isEmpty(shorePower.PowerNeed2) ? shorePower.PowerNeed2 : "";
  return "";
};

export const getFilestackPrimaryPhoto = (filestackFiles = []) => {
  const photo =
    filestackFiles.find(file => get(file, "isPrimary", null)) ||
    filestackFiles[0];

  if (photo) {
    return `https://cdn.filestackcontent.com/${get(photo, "key", "")}`;
  }

  return null;
};

export const getFilestackGalleryPhotos = (filestackFiles = []) => {
  const gallery = filestackFiles.filter(file => !get(file, "isPrimary", null));

  if (gallery.length === filestackFiles.length) {
    gallery.splice(0, 1);
  }

  return gallery.map(
    i => `https://cdn.filestackcontent.com/${get(i, "key", "")}`
  );
};

export const parseCustomFilters = filters => {
  return Object.keys(filters).reduce((acc, id) => {
    const numericId = toNumber(id);

    switch (filters[id].type) {
      case CUSTOM_FIELDS.NUMBER:
        acc.push({ Id: numericId, SelectedAmount: filters[id].value });
        break;

      case CUSTOM_FIELDS.MULTISELECT:
        acc.push({ Id: numericId, SelectedOptions: filters[id].value });
        break;

      case CUSTOM_FIELDS.SELECT:
        acc.push({ Id: numericId, SelectedOptions: [filters[id].value] });
        break;

      default:
        break;
    }

    return acc;
  }, []);
};
