import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
import { isEmpty } from "lodash";
import styles from "./styles.js";

const phone = ({
  id,
  label,
  placeHolder,
  classes,
  className,
  value,
  required,
  validate,
  submitValue,
  ...rest
}) => {
  const isValidPhone = value =>
    !isEmpty(value) ? isValidPhoneNumber(value) : true;
  return (
    <PhoneInput
      style={{
        border: !required ? "1px solid #c4c4c4" : "1px solid #C72223",
        padding: "12px",
        borderRadius: "3px"
      }}
      placeholder={placeHolder}
      value={value || ""}
      country="US"
      onChange={e => {
        return submitValue({
          id: id,
          value: e,
          required: required,
          isValid: isValidPhone(e)
        });
      }}
    />
  );
};

phone.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeHolder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  classes: PropTypes.object,
  className: PropTypes.object,
  required: PropTypes.bool,
  submitValue: PropTypes.func.isRequired,
  validate: PropTypes.func
};

export default withStyles(styles)(phone);
